import { FC, useEffect, useRef, useState } from "react";
import {
  FormProvider,
  useError,
  useWatch,
  useForm,
  useFormState,
  useValidate,
} from "recalculate-form";

import ChatBubbleIcon from "icons/chat-bubble.svg?react";

import useDispatch from "hooks/redux/use-dispatch";

import { storageActions } from "store/storage";

import reviewsApi from "api/reviews";

import { Modal, openAlert, useDialog } from "components/dialog";
import Chips from "components/chips";

import Button from "basics/button";
import { Textarea } from "basics/input";
import Radio from "basics/radio";

import ChatSkeleton from "./components/chat-skeleton";
import {
  FEEDBACK_OPTIONS,
  MAX_COMMENT_LENGTH,
  PROVIDER_LOGOS,
} from "./duck/constants";
import { FeedbackValues, FormValues } from "./duck/types";

import classes from "./styles/classes.module.scss";

const FeedbackModal: FC<TransactionInfo> = ({
  providerName,
  transactionId,
}) => {
  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const [tags, setTags] = useState([]);
  const textareaRef = useRef(null);
  const form = useForm<FormValues>({
    defaultValues: { comment: "", selectedTags: [], rating: null },
  });
  const { isSubmitting } = useFormState(form);
  const rating = useWatch<FeedbackValues>("rating", form);
  const { resolve } = useDialog();
  const { errors } = useError(form);
  const dispatch = useDispatch();

  const submitHandler = async ({
    values: { comment, selectedTags, rating },
  }: {
    values: FormValues;
  }) => {
    form.resetErrors();

    if (comment.length > MAX_COMMENT_LENGTH) {
      form.setErrors({
        comment: `Comment text should not be more than ${MAX_COMMENT_LENGTH} symbols`,
      });
      textareaRef.current?.focus();

      return;
    }

    const isSatisfied = rating === FeedbackValues.satisfied;

    try {
      await reviewsApi.createReview({
        transactionId,
        text: comment,
        rating: isSatisfied,
        tags: isSatisfied ? [] : selectedTags,
      });
      resolve(null);
      openAlert({
        message: `Thank you for helping us improve${
          isSatisfied
            ? ""
            : ". We will work on it and will reach out to you soon"
        }`,
        type: "success",
      });
    } catch {
      openAlert({
        message: "An error occurred while submitting the form",
      });
    }
  };

  useValidate(() => null, [], form);

  useEffect(() => {
    dispatch(storageActions.set({ key: "transactionInfo", value: null }));
    loadTags();
  }, []);

  const loadTags = async () => {
    setIsLoadingTags(true);
    try {
      const tags = await reviewsApi.fetchTags(transactionId);
      setTags(tags);
    } catch {}
    setIsLoadingTags(false);
  };

  const ProviderLogoIcon = PROVIDER_LOGOS[providerName];

  return (
    <Modal>
      <Modal.Header>Share your feedback</Modal.Header>
      <FormProvider form={form}>
        <form
          className={classes.form}
          onSubmit={form.handleSubmit(submitHandler)}
        >
          <div className={classes.feedbackIcon}>
            {ProviderLogoIcon ? (
              <div className={classes.providerLogoWrapper}>
                <ProviderLogoIcon />
              </div>
            ) : (
              <ChatSkeleton className={classes.providerLogoWrapper} />
            )}
            <ChatBubbleIcon />
            <ChatSkeleton />
          </div>
          <div className={classes.radioGroupWrapper}>
            <div className={classes.description}>
              How was your experience making a purchase with the{" "}
              <p className={classes.providerName}>{providerName}</p>?
            </div>
            <Radio
              name="rating"
              value={rating}
              onChange={value => {
                form.commit([{ path: "rating", value }]);
              }}
              className={classes.radioGroup}
              classNames={{ item: classes.radio }}
              items={FEEDBACK_OPTIONS}
              option={({ item: { value, icon: Icon } }) => (
                <>
                  <Icon />
                  <p className={classes.text}>{value}</p>
                </>
              )}
            />
          </div>
          <div>
            <p className={classes.description}>Tell us more</p>
            {rating === FeedbackValues.unhappy && (
              <Chips
                name="selectedTags"
                className={classes.chips}
                items={tags}
                isLoading={isLoadingTags}
              />
            )}
            <Textarea
              ref={textareaRef}
              name="comment"
              placeholder="Leave a comment"
            />
          </div>
          <Button
            disabled={!rating || Boolean(errors.comment)}
            className={classes.submitButton}
            themeName="primary"
            type="submit"
            isBlock
            isLoading={isSubmitting}
          >
            Submit
          </Button>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default FeedbackModal;

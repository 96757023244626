import { useState } from "react";
import { Link, useNavigation } from "@remix-run/react";

import LogoIcon from "icons/logo.svg?react";
import ProfileIcon from "icons/profile.svg?react";
import MenuIcon from "icons/menu.svg?react";

import Button, { BlankButton } from "basics/button";
import { LineLoader } from "basics/loader";

import { Pages } from "constants/pages";

import useWindowSizes from "hooks/use-window-sizes";

import { openProfileDrawer } from "modules/profile-drawer";

import { urlSelector } from "selectors/url";

import { useConnect } from "./duck/hooks";

import classes from "./styles/classes.module.scss";

const Header = () => {
  const { isMobileWidth } = useWindowSizes();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const { account, isLoadedAccount } = useConnect();
  const { state } = useNavigation();

  const onToggleModal = async () => {
    if (!isModalOpened) {
      setIsModalOpened(true);
    }
    await openProfileDrawer();
    setIsModalOpened(false);
  };

  const buttonProps = account
    ? {
        children: (
          <>
            <ProfileIcon className={classes.profileIcon} />{" "}
            <span className={classes.account}>
              {account.email.split("@")[0]}
            </span>
          </>
        ),
        onClick: onToggleModal,
      }
    : {
        onClick: onToggleModal,
        children: (
          <>
            <ProfileIcon className={classes.profileIcon} />
            Profile
          </>
        ),
      };

  return (
    <header className={classes.wrapper}>
      {state === "loading" && <LineLoader className={classes.lineLoader} />}
      <div className={classes.contentWrapper}>
        <Link to={Pages.home} className={classes.logoLink}>
          <LogoIcon className={classes.logoIcon} />
          <span className={classes.logoText}>Coindisco</span>
        </Link>
        <div className={classes.linkWrapper}>
          <Link className={classes.navLink} to={urlSelector.coins()}>
            Coins
          </Link>
          <Link className={classes.navLink} to={urlSelector.buy()}>
            Buy Crypto
          </Link>
          <Link className={classes.navLink} to={urlSelector.insights()}>
            Insights
          </Link>
          {/*<Link className={classes.navLink} to={urlSelector.points()}>*/}
          {/*  Points*/}
          {/*</Link>*/}
        </div>
        {isMobileWidth ? (
          <BlankButton
            className={classes.profileMobileButton}
            onClick={onToggleModal}
            disabled={!isLoadedAccount}
          >
            <MenuIcon className={classes.menuIcon} />
          </BlankButton>
        ) : (
          <>
            {isLoadedAccount ? (
              <Button
                className={classes.profileButton}
                isSelected={isModalOpened}
                themeName="primary"
                {...buttonProps}
              />
            ) : (
              <div className={classes.profileSkeletonButton} />
            )}
          </>
        )}
      </div>
    </header>
  );
};

export default Header;

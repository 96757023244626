import { FC, useEffect } from "react";
import { Outlet, useRouteError, isRouteErrorResponse } from "@remix-run/react";
import { LinksFunction, defer } from "@remix-run/cloudflare";

import store from "store";
import { accountServerThunks } from "store/account";
import { addressesServerThunks } from "store/addresses";

import { PRODUCTION_DOMAIN } from "constants/domains";

import { createMeta } from "operations/meta";
import { callCookiesContext } from "operations/cookie";
import reduxSync from "operations/redux";

import ErrorBoundaryScreen from "components/error-boundary";
import NotFound from "components/not-found";

import FeedbackModalSync from "modules/feedback-modal-sync";
import { Template, AuthTemplate } from "modules/templates";

import "./styles/global.scss";

const App: FC = reduxSync(() => (
  <AuthTemplate>
    <FeedbackModalSync />
    <Outlet />
  </AuthTemplate>
));

export const loader = ({ context }) =>
  defer({
    [accountServerThunks.loadAccountServer.typePrefix]: callCookiesContext(
      () => accountServerThunks.loadAccountServer(null, context),
      context,
    ),
    [accountServerThunks.loadPaymentMethodsServer.typePrefix]:
      callCookiesContext(
        () => accountServerThunks.loadPaymentMethodsServer(),
        context,
      ),
    [addressesServerThunks.loadAddressesServer.typePrefix]: callCookiesContext(
      () => addressesServerThunks.loadAddressesServer(),
      context,
    ),
  });

export const clientLoader = ({ serverLoader }) => {
  const accountResult = accountServerThunks.loadAccountServer.selectResult(
    store.getState(),
  );

  if (accountResult) {
    return {
      [accountServerThunks.loadAccountServer.typePrefix]: accountResult,
    };
  }

  return serverLoader();
};

export const links: LinksFunction = () => [
  {
    rel: "icon",
    type: "image/png",
    sizes: "96x96",
    href: `${PRODUCTION_DOMAIN}/assets/favicon.png`,
  },
  {
    rel: "apple-touch-icon",
    sizes: "180x180",
    href: `${PRODUCTION_DOMAIN}/assets/apple-touch-icon.png`,
  },
  { rel: "stylesheet", href: "/fonts/fonts.css" },
];

export const meta = createMeta(({ error }) => {
  if (isRouteErrorResponse(error)) {
    return {
      title: error.status === 404 ? "404 Not found" : "Error occurred",
    };
  }

  return {};
});

export const ErrorBoundary: FC = reduxSync(() => {
  const error = useRouteError();

  useEffect(() => {
    window.extensionScripts.forEach(script => {
      document.documentElement.appendChild(script);
    });
  }, []);

  // eslint-disable-next-line no-console
  console.error(JSON.stringify(error));

  if (isRouteErrorResponse(error) && error.status === 404) {
    return (
      <AuthTemplate>
        <NotFound />
      </AuthTemplate>
    );
  }

  return (
    <Template>
      <ErrorBoundaryScreen error={error as Error} />
    </Template>
  );
});

export default App;

import { FC, PropsWithChildren, ReactNode } from "react";
import { Links, Meta, Scripts, ScrollRestoration } from "@remix-run/react";

import Dialog from "components/dialog";
import Footer from "./components/footer";

interface Props extends Required<PropsWithChildren> {
  head?: ReactNode;
}

const Template: FC<Props> = ({ head, children }) => (
  <html>
    <head>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      {head}
      <Meta />
      <Links />
    </head>
    <body>
      {children}
      <Footer />
      <ScrollRestoration />
      <Dialog />
      <Scripts />
    </body>
  </html>
);

export default Template;

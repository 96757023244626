import { FC } from "react";
import joinClassNames from "classnames";
import isObject from "lodash/isObject";

import Checkbox from "basics/checkbox";

import useFieldData from "hooks/use-field-data";

import { DataType } from "./duck/types";
import { getTypedValue } from "./duck/selectors";

import classes from "./styles/classes.module.scss";

interface Props {
  className?: string;
  items: DataType[];
  value?: DataType[];
  onChange?: (activeItems: DataType[]) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  skeletonCount?: number;
  name?: string;
}

const Chips: FC<Props> = ({
  items,
  className,
  isDisabled = false,
  isLoading = false,
  skeletonCount = 5,
  ...restProps
}) => {
  const { value: values, ...inputProps } = useFieldData(restProps);

  if (!isLoading && !items.length) {
    return null;
  }

  const onChangeItem = (item: DataType) => {
    const { label, value } = getTypedValue(item);
    let newItems;

    const isActiveItem = values.find(
      activeItem => getTypedValue(activeItem).value === value,
    );

    if (isActiveItem) {
      newItems = values.filter(
        newItem => getTypedValue(newItem).value !== value,
      );
    } else {
      newItems = [...values, isObject(value) ? { label, value } : value];
    }

    inputProps.onChange(newItems);
  };

  return (
    <div className={joinClassNames(classes.wrapper, className)}>
      {isLoading
        ? Array.from({ length: skeletonCount }).map((_, index) => (
            <div key={`${index + 1}`} className={classes.chipSkeleton} />
          ))
        : items.map(item => {
            const { label, value } = getTypedValue(item);
            const isSelected = values.includes(value);

            return (
              <Checkbox
                name={restProps.name}
                className={classes.chip}
                classNames={{ label: classes.chipLabel }}
                key={value}
                disabled={isDisabled}
                checked={isSelected}
                aria-disabled={isDisabled}
                aria-selected={isSelected}
                {...inputProps}
                onChange={() => {
                  onChangeItem(item);
                }}
              >
                {label}
              </Checkbox>
            );
          })}
    </div>
  );
};

export default Chips;

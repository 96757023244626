import useSelector from "hooks/redux/use-selector";

import { accountSelectors } from "store/account";

export const useConnect = () => {
  const account = useSelector(accountSelectors.selectAccount);
  const isLoadedAccount = useSelector(state => state.account.isLoaded);

  return {
    account,
    isLoadedAccount,
  };
};
